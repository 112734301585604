/* global Linear */

import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import SmoothScroll from 'smoothscroll-polyfill';

outline();

export default function () {
    if ($('.swiper-slide') !== null) {
        import('./components/lightbox.js').then(form => {
            return form.default();
        }).catch(err => {
            console.warn(`Chunk lightbox.js loading failed: ${err}`);
        })
        import('./components/slider.js').then(form => {
            return form.init();
        }).catch(err => {
            console.warn(`Chunk slider.js loading failed: ${err}`);
        })
    }
    if ($('.appointment') !== null) {
        import('./components/contact.js').then(form => {
            return form.init();
        }).catch(err => {
            console.warn(`Chunk contact.js loading failed: ${err}`);
        })
    }
    SmoothScroll.polyfill();
    if ($('.scrolltop') !== null) {
        const upbtn = document.getElementById('scrolltop');
        
        upbtn.addEventListener('click', () => {
            window.scroll({top: 0, left: 0, behavior: 'smooth'});
        }, {passive: true});
        
        var lastScrollTop = 0;
        
        var x = 0;
        
        window.addEventListener('scroll', () => {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                document.getElementById('scrolltop').style.bottom = '-100%';
            } else {
                document.getElementById('scrolltop').style.bottom = '20px';
            }
            lastScrollTop = st;
        }, false);
    }

  if ($('.nav-tabs') !== null || $('.accordion') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
    const loadDesktopNav = () => {
        import('./components/navigation.js').then(navigation => {
            return navigation.init();
        }).catch(err => {
            console.warn(`Chunk navigation.js loading failed: ${err}`);
        });
    }
    loadDesktopNav();
    
    const loadMobileNav = () => {
        import('./components/mobileNav.js').then(mobileNav => {
            return mobileNav.init();
        }).catch(err => {
            console.warn(`Chunk mobileNav.js loading failed: ${err}`);
        });
    }
    loadMobileNav();
}
